import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Input,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { HamburgerIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';
interface Props {
  children: React.ReactNode;
  showSearchIcon?: boolean;
  toggleSearchDisplay?: () => void;
}
const PhoneDrawer = (props: Props) => {
  const router = useRouter();

  const { address: loadedAddress } = router.query;
  const { children, showSearchIcon, toggleSearchDisplay } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  useEffect(() => {
    if (isOpen) {
      onClose();
    }
  }, [loadedAddress]);
  return (
    <Box
      display={{
        base: 'block',
        md: 'none',
      }}
      position={'absolute'}
      top={-6}
      right={2}
      zIndex={20}
    >
      <Box position="relative" display="flex" justifyContent="flex-end">
        {/* {showSearchIcon && (
          <IconButton
            colorScheme="white"
            aria-label="Search database"
            icon={<SearchIcon width={4} height={4} color={'white'} />}
            onClick={toggleSearchDisplay}
          />
        )} */}
        <Button
          style={{
            background: 'transparent',
          }}
          //@ts-ignore
          ref={btnRef}
          onClick={onOpen}
          px={1}
        >
          <HamburgerIcon w={6} h={6} color="white" />
        </Button>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        //@ts-ignore
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          bg="#041320"
          minH={'180vh'}
          overflowY="scroll"
          sx={{
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <DrawerHeader>
            <HamburgerIcon onClick={onClose} w={6} h={6} />
            <ChevronRightIcon w={6} h={6} />
          </DrawerHeader>
          <DrawerBody pb={'80vh'}>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default PhoneDrawer;
