import React from 'react';

const ExpandIcon = ({ color }: any) => {
  return (
    <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.2717" y="1.23264" width="24.8889" height="18.6667" stroke={color} stroke-width="1.77778" />
      <path
        d="M20.1136 13.5314C20.3456 13.7418 20.7217 13.7418 20.9536 13.5314L23.8566 10.8951C24.3202 10.4741 24.32 9.79202 23.8563 9.37127L20.9515 6.73596C20.7195 6.52551 20.3434 6.52551 20.1115 6.73596C19.8795 6.94641 19.8795 7.2876 20.1115 7.49805L22.5976 9.7536C22.8296 9.96404 22.8296 10.3053 22.5976 10.5157L20.1136 12.7693C19.8817 12.9798 19.8817 13.3209 20.1136 13.5314Z"
        fill={color}
      />
      <path
        d="M8.02801 13.5219C7.79606 13.7323 7.42002 13.7323 7.18802 13.5219L4.28503 10.8855C3.8215 10.4646 3.82168 9.78244 4.28544 9.36174L7.19015 6.7264C7.42216 6.51597 7.7982 6.51597 8.03021 6.7264C8.26215 6.93683 8.26215 7.27805 8.03021 7.48848L5.54402 9.74408C5.31206 9.95451 5.31206 10.2957 5.54402 10.5062L8.02801 12.7598C8.26001 12.9702 8.26001 13.3114 8.02801 13.5219Z"
        fill={color}
      />
    </svg>
  );
};

export default ExpandIcon;
