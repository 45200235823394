import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalFooter,
  TableContainer,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import { cutEllipsisTxtWithLength, getEllipsisTxt } from '@/utils/format';
import { useRouter } from 'next/router';
import ConsumerBuySpot from './MsgPop/ConsumerBuySpot';
import { useBluwhale } from '@/context/BluwhaleContext';
import { deleteSpot, spotsOrders } from '../../../pages/api/consumer_points';
import Loading from '@/components/modules/DashBoard/elements/Loading';
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';

interface SpotsItem {
  address: string;
  available_spot: number;
  display_name: string;
  occupied_spot: number;
  order_id: string;
  photo_url: string;
  price: number;
  price_changes: number;
  seller_address: string;
  seller_photo_url: string;
  rank?: number;
  seller_id?: string;
}
interface MarketProps {
  hideShowSideBar: () => void;
}
export const MarketList = ({ hideShowSideBar }: MarketProps) => {
  const itemsPerPage = 10;
  const router = useRouter();
  const toast = useToast();
  const { user, axiosInstance } = useBluwhale();
  const { isOpen: isMsgOpen, onOpen: onMsgOpen, onClose: onMsgClose } = useDisclosure();
  const { isOpen: showClaimPop, onOpen: triggerClaimPop, onClose: closeClaimPop } = useDisclosure();

  const [editingPrice, setEditingPrice] = useState(false);
  const [leftData, setLeftData] = useState<SpotsItem[]>([]);
  const [rightData, setRightData] = useState<SpotsItem[]>([]);
  const [totalSpotsList, setTotalSpotsList] = useState<SpotsItem[]>([]);
  const [deleteItem, setDeleteItem] = useState<SpotsItem | undefined>(undefined);
  const [spotsTotal, setSpotsTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(spotsTotal / itemsPerPage);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    fetchSpotsMarketList(page);
  };

  const [loading, setLoading] = useState(false);

  const gotouser = (sellerAddress: string) => {
    hideShowSideBar();
    router.push(`/wallet/${sellerAddress}`);
  };

  const [targetUser, setTargetUser] = useState(undefined);
  const buyPoints = (item: any, isEdit?: string) => {
    event(events.btn_buy_market_spot, {
      target_user: item?.display_name,
      target_address: `.${item?.address}.`,
    });
    setTargetUser(item);
    if (isEdit) {
      setEditingPrice(true);
    }
    onMsgOpen();
  };

  const deleteItemFun = (item: any) => {
    setDeleteItem(item);
    triggerClaimPop();
  };
  const deleteListedSpot = async () => {
    if (axiosInstance && deleteItem?.order_id && deleteItem?.seller_id) {
      try {
        const result = await deleteSpot(axiosInstance, deleteItem?.order_id, deleteItem?.seller_id);
        toast({
          description: `Delisted spot successfully`,
          status: 'success',
          position: 'top',
        });
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
    closeClaimPop();
    fetchSpotsMarketList();
  };
  const fetchSpotsMarketList = async (page = currentPage) => {
    const { orders, total }: { orders: SpotsItem[]; total: number } = await spotsOrders(
      (page - 1) * 10,
      10,
      axiosInstance,
    );
    setSpotsTotal(total);
    if (orders) {
      const rankedOrders = await orders.map((item, index: number) => ({
        ...item,
        rank: (page - 1) * 10 + index + 1,
      }));
      setTotalSpotsList(rankedOrders);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user && axiosInstance) {
      setLoading(true);
      fetchSpotsMarketList();
    }
  }, [user, axiosInstance]);

  useEffect(() => {
    // const spotsList = totalSpotsList.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10);
    // setSpotsList(spotsList);
    let leftData = [];
    //@ts-ignore
    let rightData = [];
    if (totalSpotsList.length > 5) {
      leftData = totalSpotsList.slice(0, 5);
      rightData = totalSpotsList.slice(5, 10);
    } else {
      leftData = totalSpotsList;
    }
    setLeftData(leftData);
    //@ts-ignore
    setRightData(rightData);
  }, [currentPage, totalSpotsList]);

  if (loading) {
    return (
      <Flex minH="600px" alignItems="center" justifyContent="center">
        <Loading />
      </Flex>
    );
  }

  return (
    <Flex
      my={10}
      mx={[2, 10]}
      overflowX="hidden"
      bg="rgba(48, 48, 48, 0.5)"
      rounded={10}
      display="flex"
      justifyContent={'space-around'}
      alignItems={'center'}
      py="10"
      flexDir={['column', 'row']}
      w={['100%']}
      p={[10, 10]}
      position={'relative'}
      alignSelf={'center'}
      flex={1}
      justify="center"
      align="center"
    >
      {/* mobile view */}
      <Flex display={['flex', 'flex', 'none']} flexDir={'row'} justifyContent={'center'} pb={38}>
        <Table
          overflowY="scroll"
          size="sm"
          variant="unstyled"
          flex={1}
          overflowX="hidden"
          display={['table', 'table', 'none']}
          __css={{ 'table-layout': 'fixed', width: 'full' }}
          position="relative"
        >
          <Thead top={0} zIndex="docked" w="100%" position="sticky">
            <Tr gap={4}>
              <Th fontSize={[12]} opacity={0.5} width={['auto', 20]}>
                Rank
              </Th>
              <Th fontSize={[12]} opacity={0.5} width={['auto', 200]}>
                Profile
              </Th>
              <Th fontSize={[12]} opacity={0.5} width={['auto', 20]}>
                Seller
              </Th>
              <Th fontSize={[12]} opacity={0.5} width={['auto', 100]}>
                Spot Price
              </Th>
              <Th fontSize={[12]} opacity={0.5} width={['auto', 200]}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {totalSpotsList.map((item, index) => (
              <Tr key={`table${item?.display_name}${index}`}>
                <Td>{item?.rank}</Td>
                <Td
                  display={'flex'}
                  alignItems={'center'}
                  gap={4}
                  cursor={'pointer'}
                  position={'relative'}
                  onClick={() => gotouser(item?.address)}
                  flexDirection={'column'}
                  justifyItems={'center'}
                >
                  <Box position={'relative'}>
                    {' '}
                    <Image
                      w={8}
                      h={8}
                      src={item?.photo_url || '/images/default_avatar.png'}
                      rounded="full"
                      key={item?.photo_url + index}
                    />
                    <Box position={'absolute'} w={4} h={4} left={4} bottom={0}>
                      <Image src={'/images/icon/seat.png'} />
                      <Text fontSize={8} position={'absolute'} bottom={0.5} left={1.5}>
                        {item?.occupied_spot}
                      </Text>
                    </Box>
                  </Box>
                  <Box alignSelf={'center'} flex={1}>
                    {' '}
                    <Text fontWeight={'bold'} w={150} textAlign="center">
                      {item?.display_name
                        ? item?.display_name.startsWith('0x')
                          ? getEllipsisTxt(item?.display_name, 3)
                          : cutEllipsisTxtWithLength(item?.display_name, 15)
                        : cutEllipsisTxtWithLength(item?.address, 18)}{' '}
                    </Text>
                  </Box>
                </Td>

                <Td>
                  <Image
                    w={6}
                    h={6}
                    src={item?.seller_photo_url || '/images/default_avatar.png'}
                    rounded="full"
                    key={item?.seller_photo_url + index}
                    cursor={'pointer'}
                    onClick={() => gotouser(item?.seller_address)}
                  />
                </Td>
                <Td>
                  <Flex justifyItems={'center'} justifyContent={'flex-start'}>
                    <Flex flexDir={'column'}>
                      <Text fontWeight={'bold'}>{item?.price} BLUAI</Text>
                      <Text color={item?.price_changes > 0 ? '#00FF29' : 'red'} fontSize={12}>
                        {item?.price_changes > 0 ? '+' : ''}
                        {(item?.price_changes * 100)?.toFixed(0)}%
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
                <Td display={'flex'} justifyContent={'flex-start'}>
                  <Flex justifyContent={'center'} alignItems={'center'} gap={4}>
                    <Button
                      alignItems="center"
                      fontSize={16}
                      fontWeight="bold"
                      bg={
                        item?.seller_address?.toLowerCase() === user?.address?.toLowerCase()
                          ? '#6235D0'
                          : 'rgba(219, 219, 219, 1)'
                      }
                      color={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? '#fff' : '#000'}
                      px={6}
                      borderRadius={100}
                      onClick={() => buyPoints(item)}
                      display={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? 'none' : 'flex'}
                      isDisabled={item?.address?.toLowerCase() === user?.address?.toLowerCase()}
                    >
                      <Text textAlign="center">Buy</Text>
                    </Button>
                    <Tooltip
                      borderRadius="10px"
                      bg="rgba(255, 255, 255, 0.8)"
                      color="gray.900"
                      fontSize="12px"
                      maxWidth="150px"
                      padding="8px"
                      textAlign="center"
                      transition="opacity 0.5s ease-in-out"
                      label="Coming soon"
                    >
                      <Button
                        alignItems="center"
                        fontSize={16}
                        fontWeight="bold"
                        bg={
                          item?.seller_address?.toLowerCase() === user?.address?.toLowerCase()
                            ? '#6235D0'
                            : 'rgba(219, 219, 219, 1)'
                        }
                        color={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? '#fff' : '#000'}
                        px={6}
                        borderRadius={100}
                        display={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? 'flex' : 'none'}
                        // onClick={() => buyPoints(item, 'edit')}
                      >
                        <Text textAlign="center">Edit</Text>
                      </Button>
                    </Tooltip>
                    <CloseIcon
                      display={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? 'flex' : 'none'}
                      color="red"
                      cursor={'pointer'}
                      onClick={() => deleteItemFun(item)}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
      {/* pc view */}
      <Flex display={['none', 'none', 'flex']} flexDir={'row'} justifyContent={'center'} pb={20}>
        <TableContainer
          minH={300}
          sx={{
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          overflowX="hidden"
          flex={1}
        >
          <Table
            overflowY="scroll"
            size="sm"
            variant="unstyled"
            flex={1}
            overflowX="hidden"
            display={['none', 'table']}
            __css={{ 'table-layout': 'fixed', width: 'full' }}
            position="relative"
          >
            <Thead top={0} zIndex="docked" w="100%" position="sticky">
              <Tr gap={2}>
                <Th fontSize={[12]} opacity={0.5} width={[4, 10]}>
                  Rank
                </Th>
                <Th fontSize={[12]} opacity={0.5} width={[140]}>
                  Profile
                </Th>
                <Th fontSize={[12]} opacity={0.5} width={[4, 10]}>
                  Seller
                </Th>
                <Th fontSize={[12]} opacity={0.5} width={100}>
                  Spot Price
                </Th>
                <Th fontSize={[12]} opacity={0.5} width={100}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {leftData?.map((item, index) => (
                <Tr key={`table${item?.order_id}`}>
                  <Td>{item?.rank}</Td>
                  <Td
                    display={'flex'}
                    alignItems={'center'}
                    gap={4}
                    cursor={'pointer'}
                    position={'relative'}
                    onClick={() => gotouser(item?.address)}
                  >
                    <Image
                      w={8}
                      h={8}
                      src={item?.photo_url || '/images/default_avatar.png'}
                      rounded="full"
                      key={item?.address + index}
                    />
                    <Box position={'absolute'} w={4} h={4} left={9} bottom={1}>
                      <Image src={'/images/icon/seat.png'} />
                      <Text fontSize={8} position={'absolute'} bottom={0.5} left={1.5}>
                        {item?.occupied_spot}
                      </Text>
                    </Box>

                    <Text fontWeight={'bold'} w={300}>
                      {item?.display_name
                        ? item?.display_name.startsWith('0x')
                          ? getEllipsisTxt(item?.display_name, 3)
                          : cutEllipsisTxtWithLength(item?.display_name, 15)
                        : cutEllipsisTxtWithLength(item?.address, 18)}{' '}
                    </Text>
                  </Td>

                  <Td>
                    <Image
                      w={6}
                      h={6}
                      src={item?.seller_photo_url || '/images/default_avatar.png'}
                      rounded="full"
                      key={item?.seller_address + index}
                      cursor={'pointer'}
                      onClick={() => gotouser(item?.seller_address)}
                    />
                  </Td>
                  <Td>
                    <Flex justifyItems={'center'} justifyContent={'flex-start'}>
                      <Flex flexDir={'column'}>
                        <Text fontWeight={'bold'}>{item?.price} BLUAI</Text>
                        <Text
                          display={item?.price_changes === 0 ? 'none' : 'block'}
                          color={item?.price_changes >= 0 ? '#00FF29' : 'red'}
                          fontSize={12}
                        >
                          {item?.price_changes > 0 ? '+' : ''}
                          {(item?.price_changes * 100)?.toFixed(0)}%
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td display={'flex'} justifyContent={'flex-start'}>
                    <Flex justifyContent={'center'} alignItems={'center'} gap={4}>
                      <Button
                        alignItems="center"
                        fontSize={16}
                        fontWeight="bold"
                        bg={
                          item?.seller_address?.toLowerCase() === user?.address?.toLowerCase()
                            ? '#6235D0'
                            : 'rgba(219, 219, 219, 1)'
                        }
                        color={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? '#fff' : '#000'}
                        px={6}
                        borderRadius={100}
                        onClick={() => buyPoints(item)}
                        display={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? 'none' : 'flex'}
                        isDisabled={item?.address?.toLowerCase() === user?.address?.toLowerCase()}
                      >
                        <Text textAlign="center">Buy</Text>
                      </Button>
                      <Tooltip
                        borderRadius="10px"
                        bg="rgba(255, 255, 255, 0.8)"
                        color="gray.900"
                        fontSize="12px"
                        maxWidth="150px"
                        padding="8px"
                        textAlign="center"
                        transition="opacity 0.5s ease-in-out"
                        label="Coming soon"
                      >
                        <Button
                          alignItems="center"
                          fontSize={16}
                          fontWeight="bold"
                          bg={
                            item?.seller_address?.toLowerCase() === user?.address?.toLowerCase()
                              ? '#6235D0'
                              : 'rgba(219, 219, 219, 1)'
                          }
                          color={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? '#fff' : '#000'}
                          px={6}
                          borderRadius={100}
                          display={
                            item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? 'flex' : 'none'
                          }
                          // onClick={() => buyPoints(item, 'edit')}
                        >
                          <Text textAlign="center">Edit</Text>
                        </Button>
                      </Tooltip>
                      <CloseIcon
                        display={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? 'flex' : 'none'}
                        color="red"
                        cursor={'pointer'}
                        onClick={() => deleteItemFun(item)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Center height={300} mx={'auto'} display={['none', 'flex']} flex={0.2}>
          <Divider orientation="vertical" color="#fff" />
        </Center>

        <TableContainer
          minH={300}
          sx={{
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          overflowX="hidden"
          flex={1}
        >
          <Table
            overflowY="scroll"
            size="sm"
            variant="unstyled"
            flex={1}
            overflowX="hidden"
            display={['none', 'table']}
            __css={{ 'table-layout': 'fixed', width: 'full' }}
            position="relative"
          >
            <Thead top={0} zIndex="docked" w="100%" position="sticky">
              <Tr gap={2}>
                <Th fontSize={[12]} opacity={0.5} width={[4, 10]}>
                  Rank
                </Th>
                <Th fontSize={[12]} opacity={0.5} width={[140]}>
                  Profile
                </Th>
                <Th fontSize={[12]} opacity={0.5} width={[4, 10]}>
                  Seller
                </Th>
                <Th fontSize={[12]} opacity={0.5} width={100}>
                  Spot Price
                </Th>
                <Th fontSize={[12]} opacity={0.5} width={100}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {rightData?.map((item, index) => (
                <Tr key={`table${item?.order_id}`}>
                  <Td>{item?.rank}</Td>
                  <Td
                    display={'flex'}
                    alignItems={'center'}
                    gap={4}
                    cursor={'pointer'}
                    position={'relative'}
                    onClick={() => gotouser(item?.address)}
                  >
                    <Image
                      w={8}
                      h={8}
                      src={item?.photo_url || '/images/default_avatar.png'}
                      rounded="full"
                      key={item?.address + index}
                    />
                    <Box position={'absolute'} w={4} h={4} left={9} bottom={1}>
                      <Image src={'/images/icon/seat.png'} />
                      <Text fontSize={8} position={'absolute'} bottom={0.5} left={1.5}>
                        {' '}
                        {item?.occupied_spot}
                      </Text>
                    </Box>

                    <Text fontWeight={'bold'} w={300}>
                      {' '}
                      {item?.display_name
                        ? item?.display_name.startsWith('0x')
                          ? getEllipsisTxt(item?.display_name, 3)
                          : cutEllipsisTxtWithLength(item?.display_name, 15)
                        : cutEllipsisTxtWithLength(item?.address, 18)}
                    </Text>
                  </Td>

                  <Td>
                    <Image
                      w={6}
                      h={6}
                      src={item?.seller_photo_url || '/images/default_avatar.png'}
                      rounded="full"
                      key={item?.seller_address + index}
                      cursor={'pointer'}
                      onClick={() => gotouser(item?.seller_address)}
                    />
                  </Td>
                  <Td>
                    <Flex justifyItems={'center'} justifyContent={'flex-start'}>
                      <Flex flexDir={'column'}>
                        <Text fontWeight={'bold'}>{item?.price} BLUAI</Text>
                        <Text color={item?.price_changes >= 0 ? '#00FF29' : 'red'} fontSize={12}>
                          {item?.price_changes > 0 ? '+' : ''}
                          {(item?.price_changes * 100)?.toFixed(0)}%
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td display={'flex'} justifyContent={'flex-start'}>
                    <Flex justifyContent={'center'} alignItems={'center'} gap={4}>
                      <Button
                        alignItems="center"
                        fontSize={16}
                        fontWeight="bold"
                        bg={
                          item?.seller_address?.toLowerCase() === user?.address?.toLowerCase()
                            ? '#6235D0'
                            : 'rgba(219, 219, 219, 1)'
                        }
                        color={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? '#fff' : '#000'}
                        px={6}
                        borderRadius={100}
                        onClick={() => buyPoints(item)}
                        display={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? 'none' : 'flex'}
                        isDisabled={item?.address?.toLowerCase() === user?.address?.toLowerCase()}
                      >
                        <Text textAlign="center">Buy</Text>
                      </Button>
                      <Tooltip
                        borderRadius="10px"
                        bg="rgba(255, 255, 255, 0.8)"
                        color="gray.900"
                        fontSize="12px"
                        maxWidth="150px"
                        padding="8px"
                        textAlign="center"
                        transition="opacity 0.5s ease-in-out"
                        label="Coming soon"
                      >
                        <Button
                          alignItems="center"
                          fontSize={16}
                          fontWeight="bold"
                          bg={
                            item?.seller_address?.toLowerCase() === user?.address?.toLowerCase()
                              ? '#6235D0'
                              : 'rgba(219, 219, 219, 1)'
                          }
                          color={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? '#fff' : '#000'}
                          px={6}
                          borderRadius={100}
                          display={
                            item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? 'flex' : 'none'
                          }
                          // onClick={() => buyPoints(item, 'edit')}
                        >
                          <Text textAlign="center">Edit</Text>
                        </Button>
                      </Tooltip>
                      <CloseIcon
                        display={item?.seller_address?.toLowerCase() === user?.address?.toLowerCase() ? 'flex' : 'none'}
                        color="red"
                        cursor={'pointer'}
                        onClick={() => deleteItemFun(item)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <Flex justify="center" align="center" flex={1} position={'absolute'} bottom={10} w="100%">
        <Flex justifyContent={'center'} alignSelf={'center'} flex={1} justify="center" align="center">
          <Button
            // display={currentPage === 1 ? 'none' : 'flex'}
            variant="ghost"
            isDisabled={currentPage === 1}
            onClick={() => handleChangePage(currentPage - 1)}
          >
            <ChevronLeftIcon color={'#2B79D3'} boxSize="2em" />
          </Button>
          {Array.from({ length: totalPages }, (_, index) => (
            <Button
              key={index}
              variant={currentPage === index + 1 ? 'solid' : 'unstyled'}
              bg={currentPage === index + 1 ? '#2B79D3' : 'transparent'}
              rounded={currentPage === index + 1 ? 'full' : ''}
              onClick={() => handleChangePage(index + 1)}
              color={'#fff'}
              width={'28px'}
              fontSize={12}
            >
              {index + 1}
            </Button>
          ))}
          <Button
            // display={currentPage === totalPages ? 'none' : 'flex'}
            variant="ghost"
            isDisabled={currentPage === totalPages}
            onClick={() => handleChangePage(currentPage + 1)}
          >
            <ChevronRightIcon color={'#2B79D3'} boxSize="2em" />
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={showClaimPop} onClose={closeClaimPop}>
        <ModalOverlay
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          w="100%"
          h="100%"
          backgroundColor="rgba(0, 0, 0, 0.4)" // Adjust the background color and opacity as needed
          zIndex="1000"
        />
        <ModalContent>
          <ModalHeader color={'#CF4C54'}>Are Your Sure Recall This Spot ?</ModalHeader>
          <ModalFooter>
            <Button
              mr={3}
              onClick={closeClaimPop}
              px={10}
              bg={'transparent'}
              color={'white'}
              borderWidth={'1'}
              borderColor={'white'}
              variant="outline"
            >
              No
            </Button>
            <Button
              px={10}
              onClick={deleteListedSpot}
              bg={'transparent'}
              color={'white'}
              borderWidth={'1'}
              borderColor={'white'}
              variant="outline"
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isMsgOpen && (
        <ConsumerBuySpot
          showMessage={isMsgOpen}
          onShowMessageClose={onMsgClose}
          onShowMessageOpen={onMsgOpen}
          targetUser={targetUser}
          refetchList={fetchSpotsMarketList}
          hideLeft={true}
          editingPrice={editingPrice}
        />
      )}
    </Flex>
  );
};
