import {
  Box,
  Grid,
  Text,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  Image,
  Flex,
  Divider,
  Center,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useRouter } from 'next/router';
import Loading from '../modules/DashBoard/elements/Loading';
import NoData from '@/components/modules/DashBoard/elements/NoData';
import { cutEllipsisTxtWithLength } from '@/utils/format';
interface searchItem {
  id?: string;
  imgUrl?: string;
  name: string;
  symbol?: string;
  address: string;
  imageUrl?: string;
}
interface holderItem {
  name: string;
  address: string;
  imageUrl: string;
  usdValue: string;
}
const SearchResults = ({
  tokenList,
  walletList,
  projectList,
  nftList,
}: {
  tokenList: searchItem[];
  walletList: searchItem[];
  projectList: searchItem[];
  nftList: searchItem[];
}) => {
  const router = useRouter();
  const [tabIndex, setTabIndex] = useState(0);
  const [showTokenList, setShowTokenList] = useState(false);
  const [showNFTList, setShowNFTList] = useState(false);
  const [holderList, setHolderList] = useState<holderItem[]>([]);
  const [loadingTokenList, setLoadingTokenList] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchAddress, setSearchAddress] = useState('');
  const handleTabsChange = (index: number) => {
    if (index !== 3 && showTokenList) {
      setShowTokenList(false);
    } else if (index !== 2 && showNFTList) {
      setShowNFTList(false);
    }
    setTabIndex(index);
  };

  const gotowallet = async (wallet: string) => {
    await router.push(`/wallet/${wallet}`);
    router.reload();
  };

  const gotoProject = async (wallet?: string) => {
    if (!wallet) return;
    await router.push(`/group/${wallet}`);
  };

  const fetchHolderResult = async (address: string) => {
    setLoadingTokenList(true);
    try {
      const response = await fetch(
        `https://common-api-tivldonj5a-uc.a.run.app/api/v1/search/topHolders?address=${address}`,
      );
      const { data } = await response.json();
      setHolderList(data);
    } catch (error) {
      console.error('Error fetching default results:', error);
    }
    setLoadingTokenList(false);
  };

  const startSearchToken = async (wallet: string, name: string) => {
    setShowTokenList(true);
    setSearchName(name);
    setSearchAddress(wallet);
    fetchHolderResult(wallet);
  };
  const startSearchNFT = async (wallet: string, name: string) => {
    setShowNFTList(true);
    setSearchName(name);
    setSearchAddress(wallet);
    fetchHolderResult(wallet);
  };
  const NFTPanel = () => {
    if (showNFTList) {
      return (
        <Flex flexDir={'row'}>
          <Flex flexDir={'column'}>
            {nftList.length > 0 ? (
              nftList?.map(({ name, address, imgUrl, imageUrl, symbol }, index) => (
                <Box
                  _hover={{
                    background: '#6235d0',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  p=".5rem 1rem"
                  key={address + 'showNFTList' + index}
                >
                  <Flex flexDir={'row'} justifyItems={'center'} gap={4} onClick={() => startSearchNFT(address, name)}>
                    <Image
                      src={imgUrl || imageUrl || '/images/default_avatar.png'}
                      alt={'/images/default_avatar.png'}
                      h="8"
                      w="8"
                      borderRadius={'full'}
                      alignSelf={'center'}
                    />
                    <Box alignSelf={'center'}>
                      <Box>
                        <Text display={'inline-block'}>{(name && cutEllipsisTxtWithLength(name, 18)) || '--'}</Text>
                        <Text display={'inline-block'} opacity={0.6}>
                          ({symbol})
                        </Text>
                      </Box>
                      <Text opacity={0.5} fontSize={12}>
                        {address}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              ))
            ) : (
              <Box flex={1} justifyContent={'center'} alignSelf={'center'} justifyItems={'center'}>
                <NoData />
              </Box>
            )}
          </Flex>
          <Center height={300} mx={'auto'} display={['none', 'flex']} flex={0.2}>
            <Divider orientation="vertical" color="#fff" />
          </Center>
          {loadingTokenList ? (
            <Flex alignItems="center" justifyContent="center" minW={'50%'}>
              <Loading />
            </Flex>
          ) : (
            <Flex flexDir={'column'} flex={1}>
              <Text p=".5rem 1rem" opacity={0.5}>
                Hot
              </Text>
              {holderList?.length > 0 ? (
                holderList?.map(({ name, address, imageUrl, usdValue }, index) => (
                  <Box
                    _hover={{
                      background: '#6235d0',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    p=".5rem 1rem"
                    key={address + 'nftholders' + index}
                  >
                    <Flex flexDir={'row'} justifyItems={'center'} gap={4}>
                      <Image
                        src={imageUrl || '/images/default_avatar.png'}
                        h="8"
                        w="8"
                        borderRadius={'full'}
                        alignSelf={'center'}
                      />
                      <Box alignSelf={'center'}>
                        <Box>
                          <Text display={'inline-block'}>{(name && cutEllipsisTxtWithLength(name, 18)) || '--'}</Text>
                          {usdValue && (
                            <Text display={'inline-block'} opacity={0.6} ml={4}>
                              ${usdValue}
                            </Text>
                          )}
                        </Box>
                        <Text opacity={0.5} fontSize={12}>
                          {address}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Box mt={14} justifyContent={'center'} alignSelf={'center'}>
                  <NoData />
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      );
    } else {
      return (
        <Flex flexDir={'column'}>
          {nftList.length > 0 ? (
            nftList?.map(({ name, address, imgUrl, imageUrl, symbol }) => (
              <Box
                _hover={{
                  background: '#6235d0',
                  color: 'white',
                  cursor: 'pointer',
                }}
                p=".5rem 1rem"
                key={address}
              >
                <Flex flexDir={'row'} justifyItems={'center'} gap={4} onClick={() => startSearchNFT(address, name)}>
                  <Image
                    src={imgUrl || imageUrl || '/images/default_avatar.png'}
                    alt={'/images/default_avatar.png'}
                    h="8"
                    w="8"
                    borderRadius={'full'}
                    alignSelf={'center'}
                  />
                  <Box alignSelf={'center'}>
                    <Box>
                      <Text display={'inline-block'}>{(name && cutEllipsisTxtWithLength(name, 18)) || '--'}</Text>
                      {symbol ? (
                        <Text display={'inline-block'} opacity={0.6}>
                          ({cutEllipsisTxtWithLength(symbol, 18)})
                        </Text>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Text opacity={0.5} fontSize={12}>
                      {address}
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf={'flex-end'}>
                    <Text textAlign={'right'} color="#82FCD3">{`More >`}</Text>
                  </Box>
                </Flex>
              </Box>
            ))
          ) : (
            <Box mt={14} justifyContent={'center'} alignSelf={'center'}>
              <NoData />
            </Box>
          )}
        </Flex>
      );
    }
  };
  const TokenPanel = () => {
    if (showTokenList) {
      return (
        <Flex flexDir={'row'}>
          <Flex flexDir={'column'}>
            {tokenList.length > 0 ? (
              tokenList?.map(({ name, address, imgUrl, imageUrl, symbol }, index) => (
                <Box
                  _hover={{
                    background: '#6235d0',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  p=".5rem 1rem"
                  key={address + 'showTokenList' + index}
                >
                  <Flex flexDir={'row'} justifyItems={'center'} gap={4} onClick={() => startSearchNFT(address, name)}>
                    <Image
                      src={imgUrl || imageUrl || '/images/default_avatar.png'}
                      alt={'/images/default_avatar.png'}
                      h="8"
                      w="8"
                      borderRadius={'full'}
                      alignSelf={'center'}
                    />
                    <Box alignSelf={'center'}>
                      <Box>
                        <Text display={'inline-block'}>{(name && cutEllipsisTxtWithLength(name, 18)) || '--'}</Text>
                        {symbol ? (
                          <Text display={'inline-block'} opacity={0.6}>
                            ({cutEllipsisTxtWithLength(name, 18)})
                          </Text>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Text opacity={0.5} fontSize={12}>
                        {address}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              ))
            ) : (
              <Box mt={14} justifyContent={'center'} alignSelf={'center'}>
                <NoData />
              </Box>
            )}
          </Flex>
          <Center height={300} mx={'auto'} display={['none', 'flex']} flex={0.2}>
            <Divider orientation="vertical" color="#fff" />
          </Center>
          {loadingTokenList ? (
            <Flex alignItems="center" justifyContent="center" minW={'50%'}>
              <Loading />
            </Flex>
          ) : (
            <Flex flexDir={'column'} flex={1}>
              <Text p=".5rem 1rem" opacity={0.5}>
                Hot
              </Text>
              {holderList.length > 0 ? (
                holderList?.map(({ name, address, imageUrl, usdValue }, index) => (
                  <Box
                    _hover={{
                      //@ts-ignore
                      background: '#6235d0',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    p=".5rem 1rem"
                    key={address + 'nftholders' + index}
                  >
                    <Flex flexDir={'row'} justifyItems={'center'} gap={4}>
                      <Image
                        src={imageUrl || '/images/default_avatar.png'}
                        h="8"
                        w="8"
                        borderRadius={'full'}
                        alignSelf={'center'}
                      />
                      <Box alignSelf={'center'}>
                        <Box>
                          <Text display={'inline-block'}>{name || '--'}</Text>
                          {usdValue && (
                            <Text display={'inline-block'} opacity={0.6} ml={4}>
                              ${usdValue}
                            </Text>
                          )}
                        </Box>
                        <Text opacity={0.5} fontSize={12}>
                          {address}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Box mt={14} justifyContent={'center'} alignSelf={'center'}>
                  <NoData />
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      );
    } else {
      return (
        <Flex flexDir={'column'}>
          {tokenList.length > 0 ? (
            tokenList?.map(({ name, address, imgUrl, imageUrl, symbol }) => (
              <Box
                _hover={{
                  background: '#6235d0',
                  color: 'white',
                  cursor: 'pointer',
                }}
                p=".5rem 1rem"
                key={address}
              >
                <Flex
                  flexDir={'row'}
                  justifyItems={'center'}
                  gap={4}
                  //onClick={() => startSearchToken(address, name)}
                >
                  <Image
                    src={imgUrl || imageUrl || '/images/default_avatar.png'}
                    alt={'/images/default_avatar.png'}
                    h="8"
                    w="8"
                    borderRadius={'full'}
                    alignSelf={'center'}
                  />
                  <Box alignSelf={'center'}>
                    <Box>
                      <Text display={'inline-block'}>{(name && cutEllipsisTxtWithLength(name, 18)) || '--'}</Text>{' '}
                      {symbol ? (
                        <Text display={'inline-block'} opacity={0.6}>
                          ({cutEllipsisTxtWithLength(name, 18)})
                        </Text>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Text opacity={0.5} fontSize={12}>
                      {address}
                    </Text>
                  </Box>
                  {/* <Box alignSelf={'center'}>
                  <Text color="#82FCD3">{`More >`}</Text>
                </Box> */}
                </Flex>
              </Box>
            ))
          ) : (
            <Box mt={14} justifyContent={'center'} alignSelf={'center'}>
              <NoData />
            </Box>
          )}
        </Flex>
      );
    }
  };
  return (
    <Grid gridRowGap="1rem">
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab
            _selected={{ color: 'white' }}
            display={'flex'}
            justifyItems={'center'}
            gap={2}
            opacity={tabIndex === 0 ? 1 : 0.5}
          >
            <Image src={'/images/icon/fuzzysearch/project.svg'} w={4} /> <Text>Project</Text>
          </Tab>
          <Tab
            _selected={{ color: 'white' }}
            display={'flex'}
            justifyItems={'center'}
            gap={2}
            opacity={tabIndex === 1 ? 1 : 0.5}
          >
            <Image src={'/images/icon/fuzzysearch/wallet.svg'} w={4} /> <Text>Wallet</Text>
          </Tab>
          <Tab
            _selected={{ color: 'white' }}
            display={'flex'}
            justifyItems={'center'}
            gap={2}
            opacity={tabIndex === 2 ? 1 : 0.5}
          >
            <Image src={'/images/icon/fuzzysearch/nft.svg'} w={4} /> <Text>NFTs</Text>
          </Tab>
          <Tab
            _selected={{ color: 'white' }}
            display={'flex'}
            justifyItems={'center'}
            gap={2}
            opacity={tabIndex === 3 ? 1 : 0.5}
          >
            <Image src={'/images/icon/fuzzysearch/token.svg'} w={4} /> <Text>Token</Text>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex flexDir={'column'}>
              {projectList?.length > 0 ? (
                projectList.map(({ name, id, imgUrl, imageUrl }) => (
                  <Box
                    _hover={{
                      background: '#6235d0',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    p=".5rem 1rem"
                    key={id}
                  >
                    <Flex flexDir={'row'} justifyItems={'center'} gap={4} onClick={() => gotoProject(id)}>
                      <Image
                        src={imgUrl || imageUrl || '/images/default_avatar.png'}
                        alt={'/images/default_avatar.png'}
                        h="8"
                        w="8"
                        borderRadius={'full'}
                      />
                      <Box alignSelf={'center'}>
                        <Text>{cutEllipsisTxtWithLength(name, 18)}</Text>
                      </Box>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Box mt={14} justifyContent={'center'} alignSelf={'center'}>
                  <NoData />
                </Box>
              )}
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex flexDir={'column'}>
              {walletList?.length > 0 ? (
                walletList?.map(({ name, address, imgUrl, imageUrl }) => (
                  <Box
                    _hover={{
                      background: '#6235d0',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    p=".5rem 1rem"
                    key={address}
                  >
                    <Flex flexDir={'row'} justifyItems={'center'} gap={4} onClick={() => gotowallet(address)}>
                      <Image
                        src={imgUrl || imageUrl || '/images/default_avatar.png'}
                        alt={'/images/default_avatar.png'}
                        h="8"
                        w="8"
                        borderRadius={'full'}
                        alignSelf={'center'}
                      />
                      <Box alignSelf={'center'}>
                        <Text>{cutEllipsisTxtWithLength(name, 18)}</Text>
                        <Text opacity={0.5} fontSize={12}>
                          {address}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Box mt={14} justifyContent={'center'} alignSelf={'center'}>
                  <NoData />
                </Box>
              )}
            </Flex>
          </TabPanel>
          <TabPanel>
            <NFTPanel />
          </TabPanel>
          <TabPanel>
            <TokenPanel />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Grid>
  );
};

export default SearchResults;
