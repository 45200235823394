import React from 'react';

const MenuIcon = ({ color }: any) => {
  return (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.9401 1.99568L26.2734 1.99664M8.9401 9.99572L26.2734 9.99679M8.9401 17.9957L26.2734 17.9968M2.9401 1.99479H2.95344M2.9401 9.99479H2.95344M2.9401 17.9948H2.95344M3.60677 1.99479C3.60677 2.36298 3.30829 2.66146 2.9401 2.66146C2.57192 2.66146 2.27344 2.36298 2.27344 1.99479C2.27344 1.6266 2.57192 1.32812 2.9401 1.32812C3.30829 1.32812 3.60677 1.6266 3.60677 1.99479ZM3.60677 9.99479C3.60677 10.3629 3.30829 10.6615 2.9401 10.6615C2.57192 10.6615 2.27344 10.3629 2.27344 9.99479C2.27344 9.62666 2.57192 9.32812 2.9401 9.32812C3.30829 9.32812 3.60677 9.62666 3.60677 9.99479ZM3.60677 17.9948C3.60677 18.3629 3.30829 18.6615 2.9401 18.6615C2.57192 18.6615 2.27344 18.3629 2.27344 17.9948C2.27344 17.6267 2.57192 17.3281 2.9401 17.3281C3.30829 17.3281 3.60677 17.6267 3.60677 17.9948Z"
        stroke={color}
        stroke-width="2.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MenuIcon;
