import React, { useRef, useState } from 'react';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Fab from '@material-ui/core/Fab';
import { Compaign, Msg } from '../../../../types';
import { events } from '@/constants/analytics';
import { event } from 'nextjs-google-analytics';
import TextWithMore from '@/components/modules/Enterprise/TextWithMore';
import { useRouter } from 'next/router';
import { auth } from '@/configs/firebase';
import { isMobile } from 'react-device-detect';

import {
  Box,
  Button,
  Link,
  Image,
  Card as ChakraCard,
  Flex,
  Text,
  List,
  useDisclosure,
  Avatar as ChakraAvatar,
  Divider,
} from '@chakra-ui/react';
import { readCampaign, updateConsumerMessageStatus } from '../../../../pages/api/enterpriseapi';
import { useBluwhale } from '@/context/BluwhaleContext';
import { differenceInMilliseconds, format } from 'date-fns';
import MsgPop from '../MsgPop/MsgPop';
import { useGetConsumerCompaigns } from '@/hooks/useGetConsumerCompaigns';
import { useClaimPoint } from '../../../../redux/useClaimPoint';

interface compaignsProp {
  compaignsList: Msg[];
  myAddress: string;
  isExpand?: boolean;
  refetch: () => void;
}
const isMe = (address1: string, address2: string) => {
  return address1?.toLowerCase() === address2?.toLowerCase();
};
export const ResponsiveCarousel = ({ compaignsList: newList, isExpand, myAddress, refetch }: compaignsProp) => {
  const ref = React.useRef();
  const [time, setTime] = useState(new Date());
  const { user, axiosInstance, onLogout } = useBluwhale();
  //@ts-ignore
  const compaignsList = newList.filter((msg) => msg.text !== ' ');
  if (!isExpand) {
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
            // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
            // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
            // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
            let currentVisibleSlide = compaignsList.length;
            let maxVisibleSlide = compaignsList.length;
            if (currentVisibleSlide % 2 === 0) {
              currentVisibleSlide--;
              maxVisibleSlide--; // Increment by 1 to make it odd
            }
            //   if (parentWidth <= 1440) currentVisibleSlide = 3;
            //   if (parentWidth <= 1080) currentVisibleSlide = 1;
            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Card}
                slideWidth={parentWidth - 80}
                carouselWidth={parentWidth}
                data={compaignsList}
                // data={data}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={maxVisibleSlide}
                useGrabCursor
                fadeDistance={0.5}
                transitionTime={450}
                onActiveSlideChange={(eve) => {
                  let newTime = new Date();
                  const timeDifference = differenceInMilliseconds(newTime, time);

                  const date = new Date(timeDifference);
                  const hours = date.getUTCHours();
                  const minutes = date.getUTCMinutes();
                  const seconds = date.getUTCSeconds();
                  const formattedTime = format(new Date(0, 0, 0, hours, minutes, seconds), 'HH:mm:ss');
                  event(events.msg_timespent, {
                    duration: `${formattedTime}`,
                    msg_id: `${compaignsList?.[eve]?.message_id || compaignsList?.[eve]?.campaign_id}`,
                    consumer: `${user?.uuid}`,
                    env: process.env.NODE_ENV,
                    dispaly_type: isExpand ? 'listview' : 'carousell',
                    address: `.${user?.address}.`,
                  });
                  setTime(newTime);
                }}
              />
            );
          }}
        />
        {compaignsList?.length > 1 && (
          <Box>
            <Fab
              style={{ position: 'absolute', top: '40%', left: -30 }}
              size="small"
              color="primary"
              onClick={() => {
                //@ts-ignore
                ref.current?.goBack();
              }}
            >
              <ArrowBackIcon />
            </Fab>
            <Fab
              style={{ position: 'absolute', top: '40%', right: -30 }}
              size="small"
              color="primary"
              onClick={() => {
                //@ts-ignore

                ref.current?.goNext(6);
              }}
            >
              <ArrowForwardIcon />
            </Fab>
          </Box>
        )}
      </div>
    );
  }
  return (
    <Box
      maxH={330}
      overflowY={'auto'}
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      border={'1px solid'}
      borderColor={isExpand ? 'rgba(180, 171, 171, 0.66)' : 'rgba(97, 97, 97, 1)'}
      borderRadius={8}
      boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}
      zIndex={1}
    >
      {compaignsList.map((item, index) => (
        //@ts-ignore
        <Card
          key={index}
          data={compaignsList}
          dataIndex={index}
          isExpand={isExpand}
          myAddress={myAddress}
          refetch={refetch}
        />
        // <Card key={index} data={compaignsList} dataIndex={index} isExpand={isExpand} />
      ))}
    </Box>
  );
};

// Very import to memoize your Slide component otherwise there might be performance issue
// At minimum your should do a simple React.memo(SlideComponent)
// If you want the absolute best performance then pass in a custom comparator function like below
export const Card = (props: any) => {
  const { user, axiosInstance, onLogout } = useBluwhale();
  const { points, changePoints } = useClaimPoint();
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const conainerRef = useRef(null);
  //@ts-ignore
  const parentWidth = conainerRef?.current?.offsetWidth || 900;
  const { data, dataIndex, isExpand, myAddress, refetch } = props;
  const {
    img_url,
    description,
    button_text,
    button_url,
    project_img_url,
    project_name,
    read_status: isRead,
    sender_avatar,
    sender_name,
    text,
    message_type,
    sender_id,
    message_id,
    campaign_id,
    unclaimed_point,
  } = data[dataIndex];
  const isConsumer = message_type === 'consumer_message';

  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && user?.user_type === 'consumer') || false;
  }

  const cardClick = async () => {
    if (isConsumer) {
      try {
        if (!getEnable() || !axiosInstance) {
          return undefined;
        }

        // await updateConsumerMessageStatus(user?.address || '', sender_id, message_id, axiosInstance);
        event(events.msg_open, {
          title: `${text}`,
          msg_id: `${message_id}`,
          consumer: `${user?.uuid}`,
          env: process.env.NODE_ENV,
          dispaly_type: isExpand ? 'listview' : 'carousell',
        });
        event(events.msg_viewed, {
          title: `${text}`,
          msg_id: `${message_id}`,
          consumer: `${user?.uuid}`,
          env: process.env.NODE_ENV,
          dispaly_type: isExpand ? 'listview' : 'carousell',
        });
        onOpen();
      } catch (error: any) {
        if (error?.response?.status === 401) {
          onLogout();
          router.push('/login');
        }
        throw error;
      }
    } else {
      event(events.msg_open, {
        title: `${description}`,
        msg_id: `${campaign_id}`,
        consumer: `${user?.uuid}`,
        env: process.env.NODE_ENV,
        dispaly_type: isExpand ? 'listview' : 'carousell',
        address: `.${user?.address}.`,
      });
      event(events.msg_viewed, {
        title: `${description}`,
        msg_id: `${campaign_id}`,
        consumer: `${user?.uuid}`,
        env: process.env.NODE_ENV,
        dispaly_type: isExpand ? 'listview' : 'carousell',
        address: `.${user?.address}.`,
      });
      if (unclaimed_point > 0) {
        await changePoints(`+${unclaimed_point}`);
      }
      await readCampaign(user?.address || '', campaign_id, axiosInstance);
      refetch?.();
    }
  };
  const trackingClick = async (des: string) => {
    if (des) {
      await event(events.msg_cta, {
        title: `${des}`,
        msg_id: `${campaign_id}`,
        consumer: `${user?.uuid}`,
        env: process.env.NODE_ENV,
        dispaly_type: isExpand ? 'listview' : 'carousell',
        address: `.${user?.address}.`,
      });
    }
  };

  const closePop = async () => {
    onClose();
    refetch?.();
  };
  return (
    <Box
      p={isExpand ? 0 : 1}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={4}
      background={'transparent'}
      position={'relative'}
      w={'full'}
      cursor={'pointer'}
      onClick={cardClick}
      zIndex={1}
      borderBottom={isExpand ? '1px solid #B4ABABA8' : 'none'}
    >
      <ChakraCard
        direction={'row'}
        overflow="hidden"
        variant="outline"
        p={isExpand ? 0 : 4}
        px={[4, null, 10]}
        alignItems={'center'}
        width={'100%'}
        justifyContent={'space-between'}
        bg="#000"
        boxShadow={isExpand ? 'none' : '0px 0px 20px 0px rgba(255, 255, 255, 0.5)'}
        border={isExpand ? 'none' : '1px solid'}
        borderColor={isExpand ? 'rgba(180, 171, 171, 0.66)' : 'rgba(97, 97, 97, 1)'}
      >
        <Box
          display={'flex'}
          flexDir={['column', null, 'row']}
          gap={[4, null, 4]}
          justifyContent={'space-between'}
          alignItems={'center'}
          h={isExpand ? 'auto' : isMobile ? 200 : 90}
          minH={[isExpand ? 'auto' : 200, null, 90]}
          flex={1}
          py={isExpand ? 5 : 0}
        >
          <Box
            position={'relative'}
            display={'flex'}
            gap={2}
            alignSelf={['flex-start', 'center', 'center']}
            justifyItems={'center'}
            justifyContent={'space-between'}
          >
            {message_type === 'consumer_message' ? (
              <ChakraAvatar
                name={sender_id}
                src={isMe(sender_id, user?.address || '') ? user?.photo_url || user?.display_name : sender_avatar}
                w={10}
                h={10}
              />
            ) : (
              <Image
                objectFit="contain"
                w={10}
                h={10}
                src={project_img_url}
                alignSelf={'center'}
                alt={project_name}
                rounded="full"
              />
            )}
            <Box display={['flex', 'none', 'none']} flexDir={'row'} gap={2} alignItems={'center'}>
              {unclaimed_point ? (
                <Box
                  objectFit="contain"
                  bgImage={'/images/icon/message_points.png'}
                  bgSize="cover"
                  bgPosition="center"
                  bgRepeat="no-repeat"
                >
                  <Text fontWeight={'bold'} fontSize={10} px={2} py={1} color={'#FFB800'}>
                    {unclaimed_point}
                  </Text>
                </Box>
              ) : (
                <></>
              )}
              <Text textAlign={'left'} fontSize={20} fontWeight={isRead ? 400 : 700} opacity={isRead ? 0.6 : 1}>
                {project_name || sender_name}
              </Text>
            </Box>
            {img_url && (
              <Image
                justifySelf={'flex-end'}
                objectFit="contain"
                w="98px"
                h="55px"
                src={img_url}
                alignSelf={'flex-end'}
                fallbackSrc={project_img_url}
                flex={1}
                display={['flex', 'none', 'none']}
              />
            )}
          </Box>

          <Flex
            flexDir={'column'}
            flexGrow={1}
            flex={1}
            alignContent={'flex-start'}
            alignSelf={['flex-start', 'center', 'center']}
            ref={conainerRef}
          >
            <Box display={['none', 'flex', 'flex']} flexDir={'row'} gap={2} alignItems={'center'}>
              {unclaimed_point ? (
                <Box
                  objectFit="contain"
                  bgImage={'/images/icon/message_points.png'}
                  bgSize="cover"
                  bgPosition="center"
                  bgRepeat="no-repeat"
                >
                  <Text fontWeight={'bold'} fontSize={10} px={2} py={1} color={'#FFB800'}>
                    {unclaimed_point}
                  </Text>
                </Box>
              ) : (
                <></>
              )}
              <Text textAlign={'left'} fontSize={20} fontWeight={isRead ? 400 : 700} opacity={isRead ? 0.6 : 1}>
                {project_name || sender_name}
              </Text>
            </Box>

            {(description || text) && (
              <Box
                alignItems="center"
                fontWeight={isRead ? 400 : 700}
                fontSize={18}
                opacity={isRead ? 0.6 : 1}
                justifySelf={'flex-start'}
                alignSelf={'flex-start'}
                flex={1}
              >
                <TextWithMore
                  text={description || text}
                  limit={isConsumer ? 200 : isExpand ? 40 : 26}
                  isExpand={isExpand}
                  parentWidth={parentWidth}
                />
              </Box>
            )}
          </Flex>

          <Flex alignItems={'center'} gap={4} justifyContent={'flex-start'} flexDir={['column', null, 'row']}>
            {img_url && !isMobile && (
              <Image
                objectFit="contain"
                w="98px"
                h="55px"
                src={img_url}
                fallbackSrc={project_img_url}
                alignSelf={'center'}
              />
            )}
            {button_text && (
              <Button
                display="flex"
                alignItems="center"
                fontSize="md"
                fontWeight="bold"
                py={3}
                h={10}
                w={200}
                rounded={'full'}
                bg={'white'}
                color="#000"
              >
                <Link
                  href={button_url}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={() => trackingClick(description)}
                >
                  {button_text}
                </Link>
              </Button>
            )}
          </Flex>
        </Box>
      </ChakraCard>

      {isOpen && (
        <MsgPop
          isOpen={isOpen}
          onClose={closePop}
          isDemo={false}
          targetAddress={sender_id}
          targetImage={sender_avatar}
          enterpriseData={isConsumer ? null : data[dataIndex]}
          myAddress={myAddress}
          isConsumer={isConsumer}
        />
      )}
    </Box>
  );
};
