import { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Flex, Input, Center } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { SearchIcon } from '@chakra-ui/icons';
import SearchResults from './SearchResults';
import { v4 as uuidv4 } from 'uuid';
interface searchItem {
  id?: string;
  imgUrl: string;
  name: string;
  symbol?: string;
  address: string;
}

const useClickOutside = (callback: any) => {
  const ref = useRef(null);

  const handleClickOutside = (event: any) => {
    //@ts-ignore
    if (ref.current && !ref?.current?.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return ref;
};

const Search = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [queryText, setQueryText] = useState('');
  const [uuid, setUuid] = useState('');
  const [nftList, setNFTList] = useState<searchItem[]>([]);
  const [projectList, setProjectList] = useState<searchItem[]>([]);
  const [tokenList, setTokenList] = useState<searchItem[]>([]);
  const [walletList, setWalletList] = useState<searchItem[]>([]);
  const [abortController, setAbortController] = useState<AbortController | null>(null); // Track abort controller
  const debouncedSearch = useCallback(
    debounce((query) => {
      const controller = new AbortController();
      if (!query) return;
      setAbortController(controller);
      fetchInputResult(query, controller);
    }, 300),
    [],
  );

  const handleChange = (e: any) => {
    const newText = e.target.value;
    setQueryText(newText);
    debouncedSearch(newText);
  };

  const fetchDefaultResult = async (controller: AbortController) => {
    const uuid = uuidv4();
    setUuid(uuid);
    try {
      const response = await fetch(`https://common-api-tivldonj5a-uc.a.run.app/api/v1/search/default?uuid=${uuid}`, {
        signal: controller.signal,
      });
      const { data } = await response.json();
      const { nftList, projectList, tokenList, walletList, uuid: backenduuid } = data;
      if (backenduuid === uuid) {
        setNFTList(nftList);
        setProjectList(projectList);
        setTokenList(tokenList);
        setWalletList(walletList);
      }
    } catch (error) {
      //@ts-ignore
      if (error?.name === 'AbortError') {
        console.log('Request was aborted');
      } else {
        console.error('Error fetching input results:', error);
      }
    }
  };

  const fetchInputResult = async (query: string, controller: AbortController) => {
    const uuid = uuidv4();
    setUuid(uuid);
    try {
      const response = await fetch(
        `https://common-api-tivldonj5a-uc.a.run.app/api/v1/search/?_q=${query}&uuid=${uuid}`,
        { signal: controller.signal },
      );
      const { data } = await response.json();
      const { nftList, projectList, tokenList, walletList, uuid: backenduuid } = data;
      if (backenduuid === uuid) {
        setNFTList(nftList);
        setProjectList(projectList);
        setTokenList(tokenList);
        setWalletList(walletList);
      }
    } catch (error) {
      //@ts-ignore
      if (error?.name === 'AbortError') {
        console.log('Request was aborted');
      } else {
        console.error('Error fetching input results:', error);
      }
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleClickOutside = () => setIsFocused(false);

  const ref = useClickOutside(handleClickOutside);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  useEffect(() => {
    if (isFocused && !queryText) {
      const controller = new AbortController();
      setAbortController(controller);
      fetchDefaultResult(controller);
    }
  }, [isFocused, queryText]);

  return (
    <Box ref={ref} width="full">
      <Flex pos="relative" align="stretch">
        <Input
          p="16"
          py="2"
          bg="rgba(255, 255, 255,1)"
          rounded="full"
          placeholder="Search Address / Project / NFTs / Token "
          border="none"
          color="#000"
          width="full"
          value={queryText}
          onChange={handleChange}
          onFocus={handleFocus}
          borderColor={isFocused ? '#6235d0' : 'teal.500'}
        />
        <Center pos="absolute" left={6} h="40px" zIndex={10}>
          <SearchIcon color={isFocused ? '#6235d0' : '#AFB5BF'} boxSize="20px" />
        </Center>
      </Flex>

      {isFocused && (
        <Box maxH={450} minH={300} p="0" overflowY="auto" bg="#000000" borderRadius={10}>
          <Box px={4}>
            <Box borderTopWidth="1px" pt={2} pb={4}>
              <SearchResults
                tokenList={tokenList}
                walletList={walletList}
                projectList={projectList}
                nftList={nftList}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Search;
