import { getSimilarProject } from '@/../pages/api/enterpriseapi';
import { useBluwhale } from '@/context/BluwhaleContext';
import { AppDispatch, RootState } from './store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { setEnterpriseSimilarProjects } from './enterpriseSimilarProjects';
import { auth } from '@/configs/firebase';
import router from 'next/router';
import { trendingSpots } from '../pages/api/consumer_points';
import { setSpotsList } from './trendingspots';

export function useFetchSpotsList() {
  const { onLogout, user: bluUser, axiosInstance } = useBluwhale();

  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;
  const dispatch = useDispatch<AppDispatch>();
  const [status, setStatus] = useState('idle');
  const [initialized, setInitialized] = useState(false); // State to track initialization

  const spotsList = useSelector((state: RootState) => state.spotsList.spots);
  const changeSpotsList = useCallback(
    (details: any) => {
      dispatch(setSpotsList(details));
    },
    [dispatch, setSpotsList],
  );

  async function fetchData() {
    try {
      if (!getEnable() || status !== 'idle' || !axiosInstance) {
        return undefined;
      }
      setStatus('loading');

      const result = await trendingSpots(axiosInstance);

      setStatus('succeeded');
      await changeSpotsList(result);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // handle 401 error here
        // TODO : refresh token
        onLogout();
        router.push('/login');
      }
      setStatus('error');
      throw error;
    }
  }

  const refetch = () => {
    fetchData();
  };

  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && bluUser?.user_type === 'consumer') || false;
  }

  useEffect(() => {
    if (!spotsList?.length && user) {
      console.log(spotsList?.length, '>>>>length');
      fetchData();
    }
  }, [spotsList, user, bluUser?.user_type, bluUser]);
  return { data: spotsList, status, refetch };
}
