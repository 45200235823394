import { cutEllipsisTxtWithLength } from '@/utils/format';
import { Box, Flex, Text, Button, Image, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import { useRouter } from 'next/router';

import MsgPop from './MsgPop/MsgPop';
import { useBluwhale } from '@/context/BluwhaleContext';
import ConsumerBuySpot from './MsgPop/ConsumerBuySpot';
import { sendMsgList } from '../../../pages/api/enterpriseapi';
import { useFetchOccupiedSpots } from '@/hooks/useFetchOccupiedSpots';
import { useFetchSpotsList } from '../../../redux/useFetchSpotsList';

interface TrendingProps {
  showSidebar: boolean;
  hideShowSideBar: () => void;
}

interface TrendingItem {
  address: string;
  display_name: string;
  photo_url: string;
  total_rewards: number;
  available_rewards: number;
  current_level: number;
  total_spots: number;
  sender_name: number;
  avaiable_spots: number;
  next_level_spots: number;
  queued_users: number;
  refferals: number;
  price: number;
  required_referrals: number;
  total_points: number;
  unclaimed_points: number;
  queue_unclaimed_points: number;
  previous_referrals: number;
}
export const Trending = ({ showSidebar, hideShowSideBar }: TrendingProps) => {
  const { isOpen: isMsgOpen, onOpen: onMsgOpen, onClose: onMsgClose } = useDisclosure();
  const { isOpen: isBuyOpen, onOpen: onBuyOpen, onClose: onBuyClose } = useDisclosure();
  const { data: spots, status, refetch: refetchOccupiedSpots } = useFetchOccupiedSpots();
  const [targetAddress, setTargetAddress] = useState('');
  const [targetUser, setTargetUser] = useState(undefined);
  const buyPoints = async (item: any) => {
    event(events.btn_buy_trending_spot, {
      placement: showSidebar ? 'marketplace' : 'home',
    });
    const exist = spots?.find((spt: any) => spt?.address?.toLowerCase() === item?.address?.toLowerCase());

    setTargetAddress(item?.address);
    setTargetUser(item);
    if (exist) {
      onMsgOpen();
    } else {
      onBuyOpen();
    }
  };
  const { user, axiosInstance } = useBluwhale();
  const { data: spotsList } = useFetchSpotsList();
  const sendEmptyMessage = async () => {
    if (user?.address) {
      await sendMsgList(user?.address, targetAddress, ' ', '', axiosInstance);
      await refetchOccupiedSpots();
    }
  };

  const TrendingCard = ({ item }: { item: TrendingItem }) => {
    const router = useRouter();

    const { address, display_name: name, avaiable_spots: spot, queued_users: queue, photo_url: image, price } = item;

    let tagbg;
    let tagicon;
    let tagMsg;
    if (spot === 1) {
      tagbg = '/images/textbg/lastspot.png';
      tagicon = '/images/icon/speaker.png';
      tagMsg = `Last spot`;
    } else if (spot > 0) {
      tagbg = '/images/textbg/leftspot.png';
      tagicon = '/images/icon/speaker.png';
      tagMsg = `${spot || 0} spots left`;
    } else {
      tagbg = '/images/textbg/inline.png';
      tagicon = '/images/icon/people.png';
      tagMsg = `${queue || 0} in line `;
    }
    const gotouser = (sellerAddress: string) => {
      hideShowSideBar();
      router.push(`/wallet/${sellerAddress}`);
    };
    return (
      <Flex
        bg="rgba(255, 255, 255, 0.15)"
        py={4}
        px={6}
        rounded={10}
        flexDir="column"
        flexGrow={1}
        w={220}
        position={'relative'}
      >
        <Box
          position={'absolute'}
          top="0"
          left="0"
          bgImage={tagbg}
          width={'100%'}
          h={'100%'}
          bgRepeat={'no-repeat'}
          display={'flex'}
          justifyItems={'center'}
          justifyContent={'flex-start'}
          gap={1}
          px={1}
          bgSize={'50%'}
        >
          <Image
            src={tagicon}
            alignSelf="flex-start"
            display={'inline-block'}
            py={queue > 0 || (queue === 0 && spot === 0) ? 0.5 : 1}
          />
          <Text textAlign="left" fontSize={12} justifyItems={'center'} display={'inline-block'}>
            {tagMsg}
          </Text>
        </Box>

        <Flex
          alignItems="center"
          gap={2}
          justifyContent="flex-start"
          flexDir="row"
          my={4}
          cursor={'pointer'}
          onClick={() => gotouser(address)}
          zIndex={10}
        >
          <Box>
            <Image src={image} rounded={100} width={10} alignSelf="flex-start" />
          </Box>
          <Flex alignItems="center">
            {' '}
            {/* Adjusted alignItems */}
            <Text textAlign="left" fontSize={16} fontWeight={700} maxW={140}>
              {cutEllipsisTxtWithLength(name, 8)}
            </Text>
          </Flex>
        </Flex>
        <Button
          display="flex"
          alignItems="center"
          fontSize={14}
          fontWeight="bold"
          bg="#6235D0"
          color="#fff"
          px={10}
          borderRadius={100}
          onClick={() => buyPoints(item)}
        >
          <Text textAlign="center">
            Buy Spot{' '}
            <Text
              display={price === 1 ? 'none' : 'inline-block'}
              color={price >= 1 ? '#00FF29' : 'red'}
              fontSize={12}
              fontWeight={'400'}
            >
              {price >= 1 ? '+' : '-'}
              {((price - 1) * 100).toFixed(0)}%
            </Text>
          </Text>
        </Button>
      </Flex>
    );
  };
  const handleBackClick = () => {
    hideShowSideBar();
  };
  return (
    <Box pt={[10]} w={['100%', showSidebar ? '100%' : '94%']} ml={4}>
      <Flex mb={10} display={showSidebar ? 'flex' : 'none'}>
        <button onClick={handleBackClick}>
          <Text fontSize={20}>{`< My Profile`}</Text>
        </button>
      </Flex>
      <Flex justifyContent="space-between">
        <Text fontWeight={700} fontSize={[20, 26]} mb={6}>
          Trending Profiles
        </Text>
      </Flex>
      <Flex justifyContent="flex-start" overflowX="auto" w={['103%']} gap={10} zIndex={10}>
        {spotsList.map((item, index) => (
          //@ts-ignore
          <TrendingCard key={index} item={item} />
        ))}
      </Flex>
      {isMsgOpen && (
        <MsgPop
          isOpen={isMsgOpen}
          onClose={onMsgClose}
          isDemo={false}
          targetAddress={targetAddress}
          myAddress={user?.address}
          targetImage={'/images/demo-avatar.png'}
          targetUser={targetUser}
        />
      )}

      {isBuyOpen && (
        <ConsumerBuySpot
          showMessage={isBuyOpen}
          onShowMessageClose={onBuyClose}
          onShowMessageOpen={onBuyOpen}
          targetUser={targetUser}
          sendEmptyMessage={sendEmptyMessage}
        />
      )}
    </Box>
  );
};
